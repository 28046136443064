import { Box, Typography, Backdrop, TextField } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMemosByUser } from "../../Context/features/memoSlice";
import loaderImg from "../../assets/loader.gif";
import MemoCardsMine from "../../Component/MemoCardsMine";
import scrollreveal from "scrollreveal";

const MyMemos = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState([]);
  const [userId, setUserId] = useState(null);
  const { memosByUser, loading } = useSelector((state) => ({
    ...state.memo,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [filteredMemos, setFilteredMemos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const sr = scrollreveal({
      origin: "bottom",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        .card
    `,
      {
        opacity: 0,
        interval: 100,
      }
    );
  }, []);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("profile"));
    setName(user);
    setUserId(user?.result?.id);
  }, []);

  useEffect(() => {
    if (name?.result?.id) {
      dispatch(getMemosByUser(name?.result?.id));
    }
  }, [dispatch, name?.result?.id]);

  useEffect(() => {
    loading && setIsLoading(loading);
  }, [loading]);

  // useEffect(() => {
  //   error && toast.error(error);
  // }, [error]);

  const memoizedMyMemo = useMemo(() => memosByUser, [memosByUser]);

  useEffect(() => {
    setFilteredMemos(
      memoizedMyMemo.filter((memo) =>
        memo?.subject?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [memoizedMyMemo, searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
        marginTop: "5rem",
        marginBottom: "5rem",
        padding: { xs: "0rem", md: "2rem" },
      }}
      className="card"
    >
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <img src={loaderImg} alt="Loading..." />
        </Backdrop>
      )}
      <Typography
        component="h4"
        variant="h4"
        fontWeight="bold"
        marginBottom={2}
      >
        My Memos
      </Typography>
      {/* Search Input */}
      <TextField
        id="search"
        label="Search memo..."
        variant="outlined"
        value={searchQuery}
        onChange={handleSearch}
        fullWidth
        sx={{
          width: "95%",
          p: "2px 4px",
          // mx: 2,
          display: { xs: "flex", md: "flex", lg:"flex" },
          alignItems: "center",
          borderRadius: 20,
          flexGrow: 1,
        }}
      />
      <Box sx={{ width: "100%", mt: 2 }}>
        <MemoCardsMine memos={filteredMemos} />
      </Box>
    </Box>
  );
};

export default MyMemos;
