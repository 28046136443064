import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TablePagination,
  Stack,
  IconButton,
  Modal,
  TextField,
  ButtonGroup,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  Fab,
  Grid,
} from "@mui/material";
import { register } from "../../Context/features/authSlice";
import {
  getUsers,
  updateUser,
  deleteUser,
} from "../../Context/features/userSlice";
import { getDepartments } from "../../Context/features/departmentSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Backdrop } from "@mui/material";
import loaderImg from "../../assets/loader.gif";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "100vh",
  padding: theme.spacing(1),
  top: 0,
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(2),
  },
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "background.primary",
  color: "color.primary",
}));

const ContainerTable = styled(TableContainer)(({ theme }) => ({
  maxWidth: "100%",
}));
const SytledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const initialState = {
  firstname: "",
  lastname: "",
  middlename: "",
  email: "",
  password: "",
  phone_no: "",
  role: "",
  username: "",
  dept_id: "",
};
const User = () => {
  const [formValue, setFormValue] = useState(initialState);
  const {
    firstname,
    lastname,
    middlename,
    email,
    password,
    phone_no,
    role,
    username,
    dept_id,
  } = formValue;
  const { users, loading, error } = useSelector((state) => ({ ...state.user }));
  const { departments } = useSelector((state) => ({ ...state.department }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    loading && setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  //function called when any input  value is changed
  const onInputChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  //function called when submit button is clicked
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstname && lastname && email && password) {
      //const updatedFormValue = { ...formValue }
      dispatch(register({ formValue, navigate, toast }));
      setTimeout(() => {
        setOpen(false);
        dispatch(getUsers());
      }, 500);
    }
  };

  //function called when submit button is clicked
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (firstname && lastname && email) {
      //const updatedFormValue = { ...formValue }
      if (userId) {
        dispatch(
          updateUser({
            userId,
            updatedValue: {
              firstname,
              lastname,
              middlename,
              email,
              password,
              phone_no,
              role,
              username,
              dept_id,
            },
            navigate,
            toast,
          })
        );
      }
      setTimeout(() => {
        setOpen2(false);
        dispatch(getUsers());
      }, 500);
    }
  };

  //function called to handle edit
  const handleEdit = (id) => {
    setOpen2(true);
    if (id) {
      // eslint-disable-next-line eqeqeq
      const userDetails = users.find((data) => data.id == id);
      setFormValue(userDetails);
      setUserId(id);
    }
  };
  //function called to handle edit
  const handleDelete = (id) => {
    if (id) {
      setUserId(id);
      setOpenDialog(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyItems: "center",
      }}
    >
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <img src={loaderImg} alt="Loading..." />
        </Backdrop>
      )}
      <Typography
        component="h4"
        variant="h4"
        textAlign="center"
        sx={{ fontWeight: "bold" }}
      >
        User
      </Typography>
      <Wrapper>
        <ContainerTable component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  <Typography variant="h6">#</Typography>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Typography variant="h6">FULLNAME</Typography>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Typography variant="h6">OTHER INFO</Typography>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Typography variant="h6">ROLE</Typography>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Typography variant="h6">DEPARTMENT</Typography>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Typography variant="h6">ACTIONS</Typography>
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id + 50}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography>
                        {row.lastname} {row.middlename} {row.firstname}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.email}</Typography>
                      <Typography>{row.username}</Typography>
                      <Typography>{row.phone_no}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.role}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.dept_name}</Typography>
                    </TableCell>
                    <TableCell>
                      {/* Actions */}
                      <Stack
                        direction="row"
                        spacing={0.1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <IconButton
                          color="error"
                          onClick={() => {
                            handleEdit(row.id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => {
                            handleDelete(row.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ContainerTable>
        <Tooltip
          onClick={(e) => {
            setOpen(true);
            setFormValue(" ");
          }}
          title="Create User"
          placement="bottom"
          sx={{
            position: "fixed",
            bottom: 20,
            left: { xs: "calc(50% - 25px)", md: "50%" },
          }}
        >
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Tooltip>
        <SytledModal
          open={open}
          onClose={(e) => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            width={600}
            component="form"
            bgcolor={"background.default"}
            color={"text.primary"}
            p={3}
            noValidate
            autoComplete="off"
          >
            <Typography
              style={{ marginBottom: "20px" }}
              variant="h4"
              textAlign="left"
            >
              Create User
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  value={firstname || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  value={lastname || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  id="middlename"
                  name="middlename"
                  label="Middle Name"
                  value={middlename || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  value={email || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="username"
                  name="username"
                  label="Username"
                  value={username || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="password"
                  id="password"
                  name="password"
                  label="Password"
                  value={password || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              sx={{ width: "100%" }}
              id="role"
              select
              name="role"
              label="Role"
              value={role || ""}
              size="small"
              color="error"
              margin="dense"
              onChange={onInputChange}
            >
              <MenuItem>Select Role</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="staff">Staff</MenuItem>
            </TextField>
            <TextField
              sx={{ width: "100%" }}
              id="dept_id"
              select
              name="dept_id"
              label="Department"
              value={dept_id || ""}
              size="small"
              color="error"
              margin="dense"
              onChange={onInputChange}
            >
              <MenuItem>Select Department</MenuItem>
              {departments.map((item, index) => (
                <MenuItem key={item.id + 100} value={item.id}>
                  {item.dept_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "100%", marginBottom: { xs: "10px", md: "20px" } }}
              type="tel"
              id="phone_no"
              name="phone_no"
              label="Phone Number"
              value={phone_no || ""}
              size="small"
              color="success"
              margin="dense"
              onChange={onInputChange}
            />
            <ButtonGroup
              fullWidth
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button onClick={handleSubmit}>Add User</Button>
              <Button
                color="error"
                sx={{ width: "100px" }}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </SytledModal>
        <SytledModal
          open={open2}
          onClose={(e) => setOpen2(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            width={600}
            component="form"
            bgcolor={"background.default"}
            color={"text.primary"}
            p={3}
            noValidate
            autoComplete="off"
          >
            <Typography
              style={{ marginBottom: "20px" }}
              variant="h4"
              textAlign="left"
            >
              Update User
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  value={firstname || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  value={lastname || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  id="middlename"
                  name="middlename"
                  label="Middle Name"
                  value={middlename || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  value={email || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  id="username"
                  name="username"
                  label="Username"
                  value={username || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  required
                  type="password"
                  id="password"
                  name="password"
                  label="Password"
                  value={password || ""}
                  size="small"
                  color="error"
                  margin="dense"
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              sx={{ width: "100%" }}
              id="role"
              select
              name="role"
              label="Role"
              value={role || ""}
              size="small"
              color="error"
              margin="dense"
              onChange={onInputChange}
            >
              <MenuItem>Select Role</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="staff">Staff</MenuItem>
            </TextField>
            <TextField
              sx={{ width: "100%" }}
              id="dept_id"
              select
              name="dept_id"
              label="Department"
              value={dept_id || ""}
              size="small"
              color="error"
              margin="dense"
              onChange={onInputChange}
            >
              <MenuItem>Select Department</MenuItem>
              {departments.map((item, index) => (
                <MenuItem key={item.id + 100} value={item.id}>
                  {item.dept_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: "100%", marginBottom: { xs: "10px", md: "20px" } }}
              type="tel"
              id="phone_no"
              name="phone_no"
              label="Phone Number"
              value={phone_no || ""}
              size="small"
              color="success"
              margin="dense"
              onChange={onInputChange}
            />
            <ButtonGroup
              fullWidth
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button onClick={handleUpdateSubmit}>Update User</Button>
              <Button
                color="error"
                sx={{ width: "100px" }}
                onClick={() => setOpen2(false)}
              >
                <CloseIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </SytledModal>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Are you sure you want to delete this department ?
          </DialogTitle>
          <DialogActions>
            <Button
              autoFocus
              color="error"
              onClick={() => setOpenDialog(false)}
            >
              Disagree
            </Button>
            <Button
              color="success"
              onClick={() => {
                dispatch(deleteUser({ userId, toast }));
                setTimeout(() => {
                  setOpenDialog(false);
                  dispatch(getUsers());
                }, 500);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    </Box>
  );
};

export default User;
